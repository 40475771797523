import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';

export const datFormat = (date) => {
	return moment(date).fromNow();
};

export const datFormat2 = (date) => {
	return moment(date).format('Do MMMM, YYYY');
};

export const datFormat3 = (date) => {
	return moment(date).format('Do MMMM, YYYY hh:mma');
};

export const datFormat4 = (date) => {
	return moment(date).format('hh:mm A');
};

export const datFormat5 = (date) => {
	const possibleFormats = ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'];
	const formattedDate = moment(date, possibleFormats, true).format('YYYY-MM-DD');
	return formattedDate;
};

export function strPaddingStart(number, digits = 2, emptyDigit = 0) {
	let length = 0;
	let n = Math.abs(number);
	const absoluteNumber = n;
	do {
		n /= 10;
		// eslint-disable-next-line no-plusplus
		length++;
	} while (n >= 1);
	const prefix = Array(Math.max(digits - length + 1, 0)).join(emptyDigit);
	return number < 0 ? `-${prefix}${absoluteNumber}` : prefix + number;
}

export function capitalizeFirstLetter(str) {
	// eslint-disable-next-line prefer-template
	return (str + '').replace(/^([a-z])|\s+([a-z])/g, ($1) => {
		return $1.toUpperCase();
	});
}

export function numberWithCommas(x) {
	<NumberFormat value={x} displayType='text' thousandSeparator=',' />;
}

export function formatRole(role) {
	const val = role.split('---');
	return val[0];
}

export function formatAmount(amount, currency) {
	return amount.toLocaleString(currency.toLowerCase() === 'usd' ? 'en-US' : 'en-NG', {
		style: 'currency',
		currency: currency.toUpperCase(),
	});
}

export function ordinalSuffixOf(i) {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return `${i}st`;
	}
	if (j === 2 && k !== 12) {
		return `${i}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}

export function transCharge(amount) {
	let final = 0;
	if (amount >= 2500) {
		final += (amount + 100) / (1 - 0.015) + 1;
	} else {
		final += amount / (1 - 0.015) + 1;
	}
	const charge = final - amount;
	if (charge > 2100) {
		final = amount + 2100;
	}
	return (final * 100).toFixed();
}
