import React from 'react';
import PropTypes from 'prop-types';
import logoFile from '../assets/img/logo/logo-text-white.png';

const LogoDark = ({ width, height }) => {
	return (
		<img
			width={height !== 854 ? height * (2155 / 854) : width}
			height={width !== 2155 ? width * (854 / 2155) : height}
			style={{ objectFit: 'contain' }}
			src={logoFile}
			alt='WhiteCrust'
		/>
	);
};
LogoDark.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
LogoDark.defaultProps = {
	width: 2155,
	height: 854,
};

export default LogoDark;
