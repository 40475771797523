import React, { lazy } from 'react';
import { authMenu, dashboardMenu, loansMenu, profileMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
	CONFIRM_SIGNUP: lazy(() => import('../pages/auth/ConfirmSignUp')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/forget/Forget')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/forget/ConfirmForget')),
	RESET: lazy(() => import('../pages/auth/forget/ResetPassword')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

const PROFILE = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
	BRANCH: lazy(() => import('../pages/profile/Branch')),
};

const LOANS_MENU = {
	LOAN_INDEX: lazy(() => import('../pages/Loans/PublicIndex')),
	BOOK_LOAN: lazy(() => import('../pages/Loans/PublicCreate')),
	SINGLE_LOAN: lazy(() => import('../pages/Loans/SingleLoan')),
	BOOK_TOPUP: lazy(() => import('../pages/Loans/BookTopup')),
	CUSTOMER_LOOKUP: lazy(() => import('../pages/Loans/CustomerLookup')),
	PREVIEW_LOAN: lazy(() => import('../pages/Loans/PreviewLoan')),
	LOAN_CALCULATOR: lazy(() => import('../pages/Loans/LoanCalculator')),
};

const presentation = [
	/**
	 * Auth
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.confirmSignUp.path,
		element: <AUTH.CONFIRM_SIGNUP />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET />,
		exact: true,
		isSecured: false,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
		isSecured: false,
	},
	/**
	 * Profile
	 */
	{
		path: profileMenu.profile.path,
		element: <PROFILE.PROFILE />,
		exact: true,
		isSecured: false,
	},
	{
		path: profileMenu.branch.path,
		element: <PROFILE.BRANCH />,
		exact: true,
		isSecured: false,
	},
	/**
	 * LOANS
	 */
	{
		path: loansMenu.loanHistory.path,
		element: <LOANS_MENU.LOAN_INDEX />,
		exact: true,
		isSecured: true,
	},
	{
		path: loansMenu.bookLoan.path,
		element: <LOANS_MENU.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.bookLoan.path}/:id`,
		element: <LOANS_MENU.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.loanHistory.path}/view/:id`,
		element: <LOANS_MENU.SINGLE_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: loansMenu.searchCustomer.path,
		element: <LOANS_MENU.CUSTOMER_LOOKUP />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.bookTopUp.path}/:id`,
		element: <LOANS_MENU.BOOK_TOPUP />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.loanPreview.path}/:id`,
		element: <LOANS_MENU.PREVIEW_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: loansMenu.loanCalculator.path,
		element: <LOANS_MENU.LOAN_CALCULATOR />,
		exact: true,
		isSecured: true,
	},
];
const contents = [...presentation];

export default contents;
