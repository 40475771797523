export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	confirmSignUp: {
		id: 'confirm_register',
		text: 'Confirm your E-mail',
		path: '/account-verification',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forgot-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/verify-account',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
	},
	branch: {
		id: 'branch',
		text: 'Branch',
		path: '/branch',
		icon: 'Bank',
		hide: true,
	},
	notification: {
		id: 'notifications',
		text: 'Notifications',
		path: '/notifications',
		icon: 'Bell',
		hide: true,
	},
};

export const loansMenu = {
	loans: {
		id: 'loans',
		text: 'Loans',
		icon: 'Activity',
	},
	searchCustomer: {
		id: 'search-customer',
		text: 'Customer Lookup',
		icon: 'Search',
		path: '/search-customer',
	},
	loanCalculator: {
		id: 'loan-calculator',
		text: 'Loan Calculator',
		icon: 'Calculator',
		path: '/loan-calculator',
	},
	bookLoan: {
		id: 'book-loan',
		text: 'Book Loan',
		icon: 'FileEarmarkDiff',
		path: '/application/loans-apply',
	},
	bookTopUp: {
		id: 'topup-loan',
		text: 'Book Top Up',
		icon: 'FileCopy',
		path: '/application/loans-top-up',
		hide: true,
	},
	loanHistory: {
		id: 'loan-history',
		text: 'Loan Histories',
		icon: 'FileBreakFill',
		path: '/loans',
	},
	loanPreview: {
		id: 'loan-preview',
		text: 'Loan Preview',
		icon: 'Loan',
		path: '/loan-preview',
		hide: true,
	},
};
