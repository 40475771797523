export const links = {
	// bearerUrl: 'https://whitecrust.test/api/v1',
	// galleryUrl: 'https://whitecrust.test/storage',
	bearerUrl: 'https://api.whitecrustng.org/api/v1',
	galleryUrl: 'https://api.whitecrustng.org/storage',
	pages: {
		login: 'auth/login',
		register: 'auth/register',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'agents/profile',
		offers: 'agents/offers',
		processOffer: 'agents/offers/action',
		logout: 'logout',
		loans: 'agents/loans',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		verifyTransaction: 'agents/tranx/payment_confirmation',
		pools: 'agents/pools',
		bookInvestment: 'agents/invest_now',
		investments: 'agents/investments',
		savings: 'agents/savings',
		verifyLoan: 'agents/loan-verification',
		topUp: 'agents/loan-topup',
		dashboard: 'agents/dashboard',
		accountGeminiUtils: 'gemini_account_creation',
		loanGeminiUtils: 'gemini_loan_creation',
		bvnVerify: 'agents/verify_bvn',
		searchSalary: 'agents/search-salary',
		listBranches: 'agents/list-branches',
		updateBranches: 'agents/update-branch',
		uploadFile: 'agents/loan-file-upload',
		deleteFile: 'agents/loan-file-delete',
	},
	apis: {},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
